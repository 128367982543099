export const getFontsStyleString = () => {
  return `
    @font-face {
      font-family: 'Larsseit-Medium';
      src: url('/fonts/Larsseit-Medium.ttf') format('truetype'),
      url('/fonts/Larsseit-Medium.woff') format('woff'),
      url('/fonts/Larsseit-Medium.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'Larsseit-Regular';
      src: url('/fonts/Larsseit-Regular.ttf') format('truetype'),
      url('/fonts/Larsseit-Regular.woff') format('woff'),
      url('/fonts/Larsseit-Regular.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'PlantinStd-BoldCondensed';
      src: url('/fonts/PlantinStd-BoldCondensed.ttf') format('truetype'),
      url('/fonts/PlantinStd-BoldCondensed.woff') format('woff'),
      url('/fonts/PlantinStd-BoldCondensed.woff2') format('woff2');
      font-weight: normal;
      font-style: normal;
    }
  `;
};
