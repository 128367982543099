import React from 'react';
import AppProvider from 'store/provider';
import { getFontsStyleString } from './src/initFonts';
import firebase from 'firebase';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

export function onClientEntry() {
  const queryParams = new URLSearchParams(window.location.search);
  const isDynamicLinkUrl = window.location.pathname === '/app/';
  const hasLinkParam = queryParams.has('link');
  if (hasLinkParam && !isDynamicLinkUrl) {
    const newUrl = `${window.location.origin}/app/${window.location.search}`;
    location.replace(newUrl);
  }

  // add fonts for develop mode
  if (document.querySelectorAll('[data-id=font-defs]').length === 0) {
    const fontStyles = document.createElement('style');
    fontStyles.textContent = getFontsStyleString();
    document.head.appendChild(fontStyles);
  }

  firebase.initializeApp({
    apiKey: process.env.GATSBY_APP_FIREBASE_API_KEY,
    projectId: process.env.GATSBY_APP_FIREBASE_PROJECT_ID,
    appId: process.env.GATSBY_APP_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_APP_FIREBASE_MEASUREMENT_ID,
  });
  firebase.analytics();
}

export function onRouteUpdate({ location, prevLocation }) {
  if (prevLocation !== null) {
    firebase.analytics().setCurrentScreen(location.pathname);
    firebase.analytics().logEvent('page_view');
  }
}
